<template>
  <div>
    <!-- Filters -->
    <app-collapse hover>
      <app-collapse-item title="Filters">
        <EmailNotificationListFilters
          :status-filter.sync="statusFilter"
          :status-options="emailNotificationStatusOption"
          :type-filter.sync="typeFilter"
          :type-options="emailNotificationTypeOption"
        />
      </app-collapse-item>
    </app-collapse>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 mt-2">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox
              v-if="
                emailNotificationsMetaData.length &&
                $can('email_notification_delete')
              "
              class="mr-2"
              v-model="isDeleteMultipleCandChecked"
            >
            </b-form-checkbox>
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :searchable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div
              v-if="selectedEmailNotifications.length == 0"
              class="d-flex align-items-center justify-content-end"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search Name"
                />
              </b-input-group>
              <!-- <b-button
                v-if="$can('email_notification_create')"
                variant="primary"
                :to="{ name: 'add-email-notification' }"
              >
                <span class="text-nowrap">Add Email Notification</span>
              </b-button> -->
            </div>
            <b-button
              v-else-if="$can('email_notification_delete') && false"
              class="float-right"
              variant="danger"
              :disabled="emailNotificationsDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ emailNotificationsDeleteCount }} Email
                Notification</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-form-checkbox-group
        id="checkbox-group"
        v-model="selectedEmailNotifications"
        name="selectedEmailNotifications"
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchEmailNotificationList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(select)="data">
            <div class="text-nowrap">
              <b-form-checkbox :value="data.item.hashid"> </b-form-checkbox>
            </div>
          </template>
          <!-- Column: User -->
          <template #cell(name)="data">
            <b-media vertical-align="center">
              <b-link
                v-if="$can('email_notification_show')"
                :to="{
                  name: 'view-email-notification',
                  params: { id: data.item.hashid },
                }"
                class="font-weight-bold d-inline-block text-nowrap"
              >
                {{ data.item.name }}
              </b-link>
              <span v-else>
                {{ data.item.name }}
              </span>
            </b-media>
          </template>

          <!--Column: Status -->
          <template #cell(is_mandatory)="data">
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(
                data.item.is_mandatory
              )}`"
              class="text-capitalize"
            >
              {{ data.item.is_mandatory ? "Yes" : "No" }}
            </b-badge>
          </template>

          <!--Column: Status -->
          <template #cell(type)="data">
            <div class="text-capitalize">
              {{ data.item.type }}
            </div>
          </template>

          <!--Column: Status -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ data.item.status == "1" ? "Active" : "In Active" }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <feather-icon
              @click="editRecord(data)"
              v-if="$can('email_notification_edit')"
              v-b-tooltip.hover
              title="Edit"
              icon="EditIcon"
              size="16"
              class="mr-1 cursor-pointer"
            />
            <feather-icon
              @click="viewRecord(data)"
              v-if="$can('email_notification_show')"
              v-b-tooltip.hover
              title="View"
              icon="EyeIcon"
              size="16"
              class="cursor-pointer"
            />

            <b-dropdown
              v-if="false"
              variant="link"
              size="sm"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <b-dropdown-item
                class="btn-sm p-0"
                @click="editRecord(data)"
                v-if="$can('email_notification_edit')"
              >
                <feather-icon icon="EditIcon" size="13" class="mr-50" />
                <span>Edit</span>
              </b-dropdown-item>

              <b-dropdown-item
                class="btn-sm p-0"
                @click="viewRecord(data)"
                v-if="$can('email_notification_show')"
              >
                <feather-icon icon="EyeIcon" size="13" class="mr-50" />
                <span>View</span>
              </b-dropdown-item>

              <b-dropdown-item
                class="btn-sm p-0"
                @click="confirmDeleteRecord(data)"
                v-if="$can('email_notification_delete') && false"
              >
                <feather-icon icon="TrashIcon" size="13" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-form-checkbox-group>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="selectedEmailNotifications.length == 0"
              v-model="currentPage"
              :total-rows="totalEmailNotificationList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <b-button
              v-else-if="$can('email_notification_delete') && false"
              class="float-right"
              variant="danger"
              :disabled="emailNotificationsDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ emailNotificationsDeleteCount }} Email
                Notification</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import emailNotificationStoreModule from "../../email-settings/emailNotificationStoreModule";

import useEmailNotificationList from "./useEmailNotificationList";
import EmailNotificationListFilters from "./EmailNotificationListFilters.vue";

export default {
  components: {
    EmailNotificationListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    /* eslint-disable */
    ToastificationContent,
    /* eslint-enable */
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = "app-email-notifications";
    const isDeleteMultipleCandChecked = ref(false);
    const selectedEmailNotifications = ref([]);
    const emailNotificationsDeleteCount = ref(0);

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        REFERRAL_APP_STORE_MODULE_NAME,
        emailNotificationStoreModule
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      { label: "In Progress", code: 0 },
      { label: "Selected", code: 1 },
      { label: "Rejected", code: 2 },
    ];
    const {
      fetchEmailNotificationList,
      tableColumns,
      perPage,
      currentPage,
      totalEmailNotificationList,
      emailNotificationsMetaData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      statusFilter,
      filterByEmailNotificationUrgencyStatus,
      filterByEmailNotificationType,
      resolveUserStatusVariant,
      emailNotificationStatusOption,
      emailNotificationTypeOption,
      typeFilter,
    } = useEmailNotificationList();

    watch(isDeleteMultipleCandChecked, (val) => {
      selectedEmailNotifications.value = [];
      if (val) {
        emailNotificationsMetaData.value.forEach((email_notification) => {
          selectedEmailNotifications.value.push(email_notification.hashid);
        });
      }
    });

    watch(selectedEmailNotifications, (val) => {
      emailNotificationsDeleteCount.value = val.length;
    });

    return {
      // Sidebar
      fetchEmailNotificationList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      totalEmailNotificationList,
      // Extra Filters
      statusOptions,
      statusFilter,
      filterByEmailNotificationUrgencyStatus,
      filterByEmailNotificationType,
      selectedEmailNotifications,
      emailNotificationsDeleteCount,
      isDeleteMultipleCandChecked,
      resolveUserStatusVariant,
      emailNotificationStatusOption,
      emailNotificationTypeOption,
      typeFilter,
      emailNotificationsMetaData,
    };
  },
  data() {
    return {};
  },
  created() {
    if (!this.$can("email_notification_delete")) {
      this.tableColumns = this.tableColumns.filter(
        (item) => item.key != "select"
      );
    }
  },
  methods: {
    editRecord(data) {
      this.$router
        .push(`/edit/email-notification/${data.item.hashid}`)
        .catch(() => {});
    },
    viewRecord(data) {
      this.$router
        .push(`/view/email-notification/${data.item.hashid}`)
        .catch(() => {});
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.item.title}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.item.hashid);
        }
      });
    },
    confirmDeleteMultipleRecords() {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${this.selectedEmailNotifications.length} Email Notification, This cannot be undone.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              "app-email-notifications/removeMultipleEmailNotifications",
              this.selectedEmailNotifications
            )
            .then((res) => {
              this.isDeleteMultipleCandChecked = false;
              this.selectedEmailNotifications = [];
              if (res.data.success) {
                this.refetchData();
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Deleted Successfully.",
                //     icon: "BellIcon",
                //     variant: "success",
                //     text: res.data.message,
                //   },
                // });
                this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Deleted Successfully',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
              } else {
                // this.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Email Notification Deletion failed.",
                //     icon: "BellIcon",
                //     variant: "danger",
                //     text: res.data.message,
                //   },
                // });
                this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Email Notification Deletion failed..',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });

              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
    refetchEmailNotificationsData() {
      console.log("Added Email Notification");
    },
    deleteRecord(id) {
      const self = this;
      this.$store
        .dispatch("app-email-notifications/removeEmailNotification", id)
        .then((res) => {
          if (res.status == "204") {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Email Notification Deleted",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: "Email Notification Deleted Successfully.",
            //   },
            // });

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Email Notification Deleted',
                icon: 'BellIcon',
                variant: 'success',
                text: 'Email Notification Deleted Successfully',
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
          } else {
            // self.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Email Notification Deletion failed.",
            //     icon: "BellIcon",
            //     variant: "danger",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Email Notification Deletion failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
          }
          self.refetchData();
        })
        .catch((err) => {
          // self.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Email Notification Deletion failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: `Email Notification Deletion failed.`,
                icon:'BellIcon',
                variant : 'danger',
                hideClose: true,
                
              },
                  },
                  {
              timeout : 3000,
              position : 'bottom-center',
              toastClassName:"error-info",
              hideProgressBar : true,
                  })
          console.error(err);
        });
    },
    openWindow(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
